import { of } from "rxjs";
import { first, timeoutWith } from "rxjs/operators";
import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";

export function useAnalyticsOnce(product, feature, event, data) {
  useEffect(() => {
    track(product, feature, event, data);
  }, []);
}

export function useAnalytics(product, feature, event, data) {
  const [resend, setResend] = useState(0);

  useEffect(() => {
    if (resend) {
      track(product, feature, event, data);
    }
  }, [resend]);

  return () => setResend(resend + 1);
}

/**
 * Note: all events (eg: "product.feature") must be whitelisted
 * in the analytics service (back-end) for this to work
 */
export async function track(product, feature, event, _data = {}) {
  if (!product) throw new Error("Analytics events must require a product");
  if (!feature) throw new Error("Analytics events must require a feature");
  if (!event) throw new Error("Analytics events must require a event name");
  if (typeof _data !== "object")
    throw new Error("Analytics event data must be an object");

  const data = Object.assign(_data, {
    user_agent: navigator.userAgent,
  });

  const stack = new Error().stack;
  const { user, tenant } = await getLoggedInUserAndTenant();
  const nowDate = getNowDate();
  const userId = user && user.id;
  const tenantId = tenant && tenant.id;
  const { appIsMobile, nativeMobileSource } = window;

  let client_app_name = "canopy_web";
  if (appIsMobile) {
    client_app_name = nativeMobileSource || "canopy_mobile_web";
  }

  try {
    const resp = await fetch(`/api/analytics`, {
      method: "POST",
      body: JSON.stringify({
        header: {
          client_app_name,
          originator: "frontend",
          correlation_id: uuid(),
          parameters: {
            products: product,
            features: feature,
            events: event,
          },
          "X-Canopy-Tenant-ID": tenantId,
          "X-Canopy-User-ID": userId,
        },
        body: {
          data: data,
          event_date: nowDate.getTime(),
          // The userId from the given event may not be defined.
          // Also, the moment we are processing the event the user still may not be defined.
          // If neither, send up 0 for the tenant_id and "0" user_id.
          tenant_id:
            event.tenantId ||
            (event.tenant && event.tenant.id) ||
            tenantId ||
            0,
          user_id:
            event.userId || (event.user && event.user.id) || userId || "0",
        },
      }),
    }).catch((error) => recordException(error, stack));
    if (!resp.ok) throw new Error(`Cannot save event: ${resp.status}`);
  } catch (e) {}
}

async function getLoggedInUserAndTenant() {
  const auth = await SystemJS.import("cp-client-auth!sofe");

  const user = await auth.default
    .getLoggedInUserAsObservable()
    .pipe(first(), timeoutWith(500, of(null)))
    .toPromise();
  const tenant = await auth.default
    .getTenantAsObservable()
    .pipe(first(), timeoutWith(500, of(null)))
    .toPromise();

  return { user, tenant };
}

function getNowDate() {
  const now = new Date();

  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes()
  );
}

function recordException(error, asyncStacktrace) {
  error.showToast = false;
  SystemJS.import("error-logging!sofe").then((sentryErrorLogging) => {
    sentryErrorLogging.captureException(error, { asyncStacktrace });
  });
}
